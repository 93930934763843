export const exceptionDeliveryDates = [
  {
    originalDate: new Date('2024-04-01'),
    exceptionDate: new Date('2024-04-02'),
  },
];

export const getExceptionDeliveryDate = (originalDate: Date) => {
  const exceptionDelivery = exceptionDeliveryDates.find(
    (exceptionDeliveryDate) =>
      exceptionDeliveryDate.originalDate.toLocaleDateString('en-US') ===
      originalDate.toLocaleDateString('en-US')
  );

  if (exceptionDelivery) return exceptionDelivery.exceptionDate;
  return undefined;
};
