import type { VNode } from 'vue';
import { getLoyaltyDiscountPercentage } from '@ruokaboksi/utils';
import type { DeliveryWeek } from '@/models';
import IconCampaign from '@/assets/icons/icon-campaign.svg?component';
import IconKokkiKlubi from '@/assets/icons/icon-kokkiklubi.svg?component';
import Badge from '@/components/Badge';
import { useCustomerCurrency } from '@/composables/useCustomerCurrency';

export default defineComponent({
  name: 'WeekDiscountBadge',
  props: {
    deliveryWeek: {
      required: true,
      type: Object as PropType<DeliveryWeek>,
    },
    size: {
      default: 'normal',
      type: String as PropType<'normal' | 'mini'>,
    },
  },
  setup(props) {
    const { currentCustomer } = useCurrentCustomer();
    const { formatPrice } = useCustomerCurrency();

    const isCampaignActive = computed(() => {
      return (
        props.deliveryWeek.activeCampaign &&
        props.deliveryWeek.totalPrice.discount > 0
      );
    });

    const isLoyaltyActive = computed(() => {
      return (
        currentCustomer.value?.inLoyaltyProgram &&
        props.deliveryWeek.loyaltyLevel > 1
      );
    });

    const discount = computed(() => {
      if (isCampaignActive.value) {
        return {
          icon: () => <IconCampaign class="icon" />,
          children: `-${formatPrice(props.deliveryWeek.totalPrice.discount)}`,
          type: props.deliveryWeek.paused ? 'caution' : 'success',
        } as const;
      } else if (isLoyaltyActive.value) {
        if (props.size === 'mini') {
          return {
            icon: () =>
              Array.from(
                { length: props.deliveryWeek.loyaltyLevel },
                (_, index) => <IconKokkiKlubi key={index} class="icon" />
              ),
            children: null,
            type: 'loyalty',
          } as const;
        }

        return {
          icon: () => <IconKokkiKlubi class="icon" />,
          children: `-${getLoyaltyDiscountPercentage(
            props.deliveryWeek.loyaltyLevel
          )}%`,
          type: 'loyalty',
        } as const;
      } else {
        return null;
      }
    });

    return {
      discount,
    };
  },
  render(): VNode | null {
    if (this.discount) {
      return (
        <Badge
          v-slots={{ icon: this.discount.icon }}
          type={this.discount.type}
          size={this.size}
        >
          {this.discount.children}
        </Badge>
      );
    } else {
      return null;
    }
  },
});
